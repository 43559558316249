@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Yeseva One", "Poiret One", Roboto "Helvetica Neue",
		BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Open Sans",
		"Helvetica Neue", sans-serif;
	font-size: 16px;
	background: #141414;
}

.dottedLine {
	text-align: center;
	position: relative;
	z-index: 10;
}

.dottedLine::after {
	content: "";
	position: absolute;
	top: -10px;
	left: 25px;
	right: 0;
	border-top: dashed 1px #ad987d;
	z-index: -1;
}

.line-center {
	margin: 0;
	padding: 0 10px;
	display: inline-block;
	background: #141414;
}

.centerLine {
	text-align: center;
	position: relative;
	z-index: 2;
}
.centerLine:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	border-top: solid 1px white;
	z-index: -1;
}

.leftLine {
	text-align: right;
	position: relative;
	z-index: 2;
}
.leftLine:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	border-top: solid 1px #ad987d;
	z-index: -1;
}

.horisontal-center {
	margin: 0;
	padding: 0 10px;
	display: inline-block;
}

.topLine {
	text-align: center;
	position: relative;
	z-index: 2;
	top: 20px;
}
.topLine:after {
	content: "";
	position: absolute;
	width: 2px;
	height: 30px;
	top: -40px;
	left: 50%;
	right: 0;
	border-right: solid 1px #f7e7ce;
	z-index: -10;
}

/* .background_block {
	position: relative;
}

.background_block:after {
	content: "";
	background: #ad987d;
	width: 850px;
	height: 100px;
	top: -100px;
	position: absolute;
} */
